import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Divider,
  Avatar,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import axios from 'axios';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Import the copy icon



const OrderRowPage = () => {
  const { trade_uuid } = useParams(); // Use the full trade_uuid
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(trade_uuid); // Copy the full trade_uuid to the clipboard
  };
  const navigate = useNavigate();

  const [order, setOrder] = useState(null); // State to hold the order data
  const [error, setError] = useState(null);
  const [openTrackingDialog, setOpenTrackingDialog] = useState(false);
  const [trackingID, setTrackingID] = useState('');
  const [carrier, setCarrier] = useState(''); // State to hold the carrier
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    if (order && order.product_id) {
      const cachedProduct = localStorage.getItem(order.product_id);
      if (cachedProduct) {
        setProductDetails(JSON.parse(cachedProduct));
      }
    }
  }, [order]);

  // List of common carriers
  const carriers = [
    'UPS',
    'FedEx',
    'DHL',
    'USPS',
    'Canada Post',
    'Royal Mail',
    'Australia Post',
    'Japan Post',
    'La Poste',
    'Other',
  ];

  const fetchOrder = useCallback(async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    if (isLocal) {
      try {
        const mockData = await import('./fixtures/trades.json'); // Local mock data
        const orders = mockData.default;
        const orderData = orders.find((order) => order.trade_uuid === trade_uuid);
        if (orderData) {
          setOrder(orderData);
        } else {
          setError('Order not found');
        }
      } catch (error) {
        console.error('Error loading mock data:', error);
        setError('Failed to load mock data');
      }
    } else {
      try {
        const response = await axios.get('/api/orders/sell/trades'); // API request for production
        const orders = response.data;
        const orderData = orders.find((order) => order.trade_uuid === trade_uuid); // Filter by trade_uuid
        if (orderData) {
          setOrder(orderData);
        } else {
          setError('Order not found');
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to fetch orders');
      }
    }
  }, [trade_uuid]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  // this opens the modal to add a shipment label
  const OpenAddTrackingModal = () => {
    setOpenTrackingDialog(true); // Open the tracking ID dialog
  };

  const DownloadFile = () => {
    const shipmentLabel = order.shipment_label; // Assuming 'order' is available in the context
  
    if (shipmentLabel) {
      window.open(shipmentLabel, '_blank'); // Opens the link in a new tab and triggers the download
    } else {
      alert('No shipment label available for download');
    }
  };

  // Closes the new shipment label modal 
  const CloseAddTrackingModal = () => {
    setOpenTrackingDialog(false);
  };

  const handleAddTrackingID = async () => {
    if (!trackingID || !carrier || !order || !order.address) {
      // Don't proceed if there is no associated customer address
      return; // Don't proceed if tracking ID, carrier, or order is not available
    }

    const shipmentData = {
      order_uuid: order.order_uuid,
      trade_uuid: trade_uuid,
      status: 'pre_transit',
      carrier: carrier,
      tracking_number: trackingID,
      address_uuid: order.address && order.address.address_uuid ? order.address.address_uuid : null,
    };

    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      // Mock the API call locally
      console.log('Mocking shipment creation:', shipmentData);
      setTimeout(() => {
        console.log('Shipment created successfully (mocked)');
        setOrder({ ...order, shipment_id: 'mock-shipment', tracking_number: trackingID });
        setOpenTrackingDialog(false);
      }, 1000); // Simulate delay
    } else {
      // Send request to backend for production
      try {
        const response = await axios.post('/api/create-shipment', shipmentData);
        if (response.status === 200) {
          setOrder({ ...order, shipment_id: 'shipment_id', tracking_number: trackingID });
          console.log('Shipment created successfully');
        }
      } catch (error) {
        console.error('Error creating shipment:', error);
      }
      setOpenTrackingDialog(false);
    }
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!order) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Utility function to format cents to dollars
  const formatToDollars = (cents) => {
    return `$${(cents / 100).toFixed(2)}`;
  };
    
  // Utility function to convert the shipment status to human-readable format
  const getHumanReadableStatus = (status) => {
    const statusMap = {
      pre_transit: "Pre Transit",
      in_transit: "In Transit",
      out_for_delivery: "Out for Delivery",
      delivered: "Delivered",
      return_to_sender: "Return to Sender",
      failure: "Failure",
      unknown: "Unknown",
    };

    // Return the mapped status or 'Unknown' if not found
    return statusMap[status] || "Unknown";
  };

  // Get last 6 digits for display as order number
  const displayOrderNumber = trade_uuid.slice(-6).toUpperCase();

  const verifiedStatus = order.product_verified ? 'Verified' : 'Not Verified';
  const verifiedColor = order.product_verified ? 'success' : 'warning';


  // const fulfillmentStatus = (order.vendor_shipment_status === 'delivered') ? 'Fulfilled' : 'Unfulfilled';
  const fulfillmentStatus = (order.vendor_shipment_status === 'delivered') 
  ? 'Fulfilled' 
  : (order.vendor_shipment_status === 'in_transit' || order.vendor_shipment_status === 'out_for_delivery') 
    ? 'Shipped' 
    : 'Unfulfilled';

  const fulfillmentColor = (fulfillmentStatus === 'Fulfilled') 
    ? 'success' 
    : (fulfillmentStatus === 'Shipped') 
      ? 'success'
      : 'warning';
  const deliveryStatus = getHumanReadableStatus(order.vendor_shipment_status);
  const paymentStatus = order.paid_status === 'cleared' 
  ? 'Paid' 
  : order.paid_status === 'pending' 
    ? 'Payment Pending'
    : order.paid_status || 'Unpaid'; // Set payment status with default fallback

  // const paymentStatus = order.paid_status === 'cleared' ? 'Paid' : order.paid_status || 'Unpaid'; // Set payment status with default fallback
  const paymentColor = paymentStatus === 'Paid' 
  ? 'success' 
  : paymentStatus === 'Payment Pending'  // Handle the new text
    ? 'warning'  // Orange for 'Payment Pending'
    : 'default'; // Default for unpaid or other statuses


  return (
    <Box sx={{ padding: 4 }}>
      {/* Header with Back Button and Trade Number */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Button onClick={handleBackClick} startIcon={<ArrowBackIcon />} sx={{ textTransform: 'none' }}>
          Orders
        </Button>
        <Typography variant="h6" component="div" sx={{ ml: 2, fontWeight: 'bold' }}>
          Trade #{displayOrderNumber}
        </Typography>
        {/* Copy to Clipboard Button */}
        <Tooltip title="Copy full Trade UUID">
          <IconButton onClick={handleCopyToClipboard} sx={{ ml: 1 }}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>

        <Box sx={{ flexGrow: 1 }} />
        {/* Status Chips */}
        <Chip
          label={verifiedStatus}
          color={verifiedColor}
          variant="outlined"
          sx={{ mr: 1 }}
        />
        <Chip
          label={paymentStatus}
          color={paymentColor}
          variant="outlined"
          sx={{ mr: 1 }}
        />
        <Chip
          label={fulfillmentStatus}
          color={fulfillmentColor}
          sx={{ mr: 1 }}
        />
        <Chip
          label={deliveryStatus}
          color={deliveryStatus === 'Delivered' ? 'success' : 'warning'}
          sx={{ mr: 1 }}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Main Content */}
      <Grid container spacing={4}>
        {/* Left Column */}
        <Grid item xs={12} md={8}>
          {/* Fulfillment Actions */}
          {/* Order Details */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }} gutterBottom>
              Order Details
            </Typography>
            {/* Product Item */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                variant="square"
                src={productDetails?.thumbnail || '/placeholder.png'}
                sx={{ width: 64, height: 64, mr: 2 }}
              />
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body1">{order.product_name || 'Product Name'}</Typography>
                <Typography variant="body2" color="textSecondary">
                  SKU: {order.product_id || 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Name: {productDetails?.title || 'Product Name'}
                </Typography>
              </Box>
              <Typography variant="body1">
                {order.volume} × {formatToDollars(order.price)}
              </Typography>
            </Box>

            <Divider />

            {/* Order Summary */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Box sx={{ width: '50%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Subtotal</Typography>
                  <Typography variant="body2">
                    {formatToDollars(order.gross_amount)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Tax</Typography>
                  <Typography variant="body2">{formatToDollars(order.tax_fee)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Shipping</Typography>
                  <Typography variant="body2">{formatToDollars(order.shipping_fee)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">App Fee</Typography>
                  <Typography variant="body2">{formatToDollars(order.app_fee)}</Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {formatToDollars(order.amount_total)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>

          {/* Notes */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }} gutterBottom>
              Notes
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'left' }}  color="textSecondary">
              No notes from the customer.
            </Typography>
          </Paper>

          {/* Order History */}
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }} gutterBottom>
              Order History
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Trade executed"
                  secondary={new Date(order.timestamp).toLocaleString()}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Right Column */}
        {/* Shipment Information */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Shipment Information
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                      Shipment Status:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2">
                      {order.vendor_shipment_status ? (
                        order.vendor_shipment_status
                      ) : (
                        <Tooltip title="Shipment status will be updated soon" arrow>
                          <span>processing</span>
                        </Tooltip>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                      Tracking Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2">
                      {order.vendor_shipment_tracking_id ? (
                        <a
                          href={`https://www.fedex.com/fedextrack/?trknbr=${order.vendor_shipment_tracking_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {order.vendor_shipment_tracking_id}
                        </a>
                      ) : (
                        <Tooltip title="Tracking number will be available soon" arrow>
                          <span>Processing label</span>
                        </Tooltip>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Tooltip
                title={
                  order.vendor_shipment_tracking_id
                    ? ''
                    : 'Label will be available to download here soon'
                }
                arrow
              >
                <span> {/* Wrapping Button inside a span to properly apply the tooltip to disabled buttons */}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LocalShippingIcon />}
                    onClick={order.vendor_shipment_tracking_id ? DownloadFile : null}
                    disabled={!order.vendor_shipment_tracking_id}
                    sx={{ mt: 1 }} // Optional: Adds a margin-top for spacing
                  >
                    Download Shipping label
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Paper>

          {/* Customer Information */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Contact Information
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Fulfillment Ops
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2">fulfillment-ops@goldwaterbullion.com</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                      Phone:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2">415-439-3080</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>

          {/* Shipping Address */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Shipping Address
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Goldwater Bullion Exchange, Inc
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  2607 Wolfin Ave
                  PMB 239
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Amarillo TX, 79109
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Dialog for adding tracking ID */}
      <Dialog open={openTrackingDialog} onClose={CloseAddTrackingModal}>
        <DialogTitle>Add Tracking Information</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Select Carrier</InputLabel>
            <Select
              value={carrier}
              onChange={(e) => setCarrier(e.target.value)}
              label="Select Carrier"
              fullWidth
            >
              {carriers.map((carrierOption) => (
                <MenuItem key={carrierOption} value={carrierOption}>
                  {carrierOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            label="Tracking Number"
            type="text"
            fullWidth
            value={trackingID}
            onChange={(e) => setTrackingID(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: 2 }}>
          <Button onClick={CloseAddTrackingModal} size="small">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTrackingID}
            size="small"
            sx={{ ml: 1, justifyContent: 'flex-start' }} // Adding margin between buttons
          >
            Add Shipment Label
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrderRowPage;
