import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Button, Stack, Paper, Tooltip, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Chip from '@mui/material/Chip';

const OrderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const OrderItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
}));

const OrderDetailsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const OrderHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(1.5),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(1.5),
}));

const ThreeColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const Column = styled(Box)(({ theme }) => ({
  width: '32%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  fontSize: '14px',
}));

const OrderDetails = () => {
  const { trade_uuid } = useParams();
  const { auth } = useAuth();
  const [order, setOrder] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [shipmentStatus, setShipmentStatus] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const isLocal = process.env.REACT_APP_LOCAL === 'true';
  const address = selectedAddress || order?.address;

  useEffect(() => {
    const fetchOrderDetails = async () => {
      let fetchedOrders = [];

      if (isLocal) {
        const staticData = await import('./fixtures/orders.json');
        fetchedOrders = staticData.default;
      } else {
        const response = await axios.get('/api/orders');
        fetchedOrders = response.data;
      }

      const filteredOrder = fetchedOrders.find(order => order.trade_uuid === trade_uuid);

      if (filteredOrder) {
        setOrder(filteredOrder);
        fetchProductDetails(filteredOrder.product_id);
      }
    };

    const fetchProductDetails = async (productId) => {
      try {
        let productData;
        if (isLocal) {
          const productStatic = await import('./fixtures/productImage.json');
          productData = productStatic.default;
        } else {
          const response = await axios.get(`/api/products/uuid/${productId}`);
          productData = response.data;
        }

        setProductDetails({
          img: productData.img,
          title: productData.title,
          slug: productData.slug,
        });
      } catch (error) {
        console.error(`Error fetching product details for product ID: ${productId}`, error);
      }
    };

    const fetchAddresses = async () => {
      try {
        let addressData;
        if (isLocal) {
          const mockAddressData = await import('./fixtures/addresses.json');
          addressData = mockAddressData.default;
        } else {
          const response = await axios.get('/api/addresses');
          addressData = response.data;
        }
        setAddresses(addressData);

        if (addressData.length > 0) {
          setSelectedAddress(addressData[0]);
        }
      } catch (error) {
        console.error("Error fetching addresses", error);
      }
    };

    if (auth.user) {
      fetchOrderDetails();
      fetchAddresses(); // Fetch addresses separately to avoid repeated API calls
    }
  }, [auth.user, trade_uuid, isLocal]);

  const calculateGrandTotal = (price, volume) => {
    return (price * volume / 100).toFixed(2);
  };

  const calculateSavings = (price, bid, volume) => {
    const totalPaid = price * volume;
    const totalBid = bid * volume;
    return ((totalPaid - totalBid) / 100).toFixed(2);
  };

  const renderShipmentStatus = () => {
    if (shipmentStatus) {
      return (
        <Typography variant="h6" gutterBottom>
          Shipment Status: {shipmentStatus}
        </Typography>
      );
    } else {
      return (
        <Typography variant="h6" gutterBottom>
          Preparing for shipment
        </Typography>
      );
    }
  };

  const renderShipToTooltip = () => {
    if (address && address.name) {
      return (
        <Tooltip
          title={
            <>
              <Typography>{address.name}</Typography>
              <Typography>{address.street1}</Typography>
              <Typography>{`${address.city}, ${address.state} ${address.zip}`}</Typography>
              <Typography>{address.phone}</Typography>
              <Typography>{address.email}</Typography>
            </>
          }
        >
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Typography variant="body2" color="primary">
              {address.name}
            </Typography>
            <KeyboardArrowDownIcon fontSize="small" color="primary" sx={{ marginLeft: 1 }} />
          </Box>
        </Tooltip>
      );
    }

    return <Typography variant="body2" color="textSecondary">No address provided</Typography>;
  };

  if (!order) {
    return <Typography>Loading order details...</Typography>;
  }

  return (
    <Container>
      <Box sx={{ margin: 3 }}>
        {/* Order Details Header */}
        <Typography variant="h4" gutterBottom align="left"> Order Details</Typography>
        <Typography variant="body2" gutterBottom align="left">
          Ordered on {new Date(order.created_at).toLocaleDateString()} | Trade # {trade_uuid}
        </Typography>

        {/* Three-column box */}
        <ThreeColumnBox>
          {/* Column 1: Shipping Address */}
          <Column>
            <Typography variant="h6" gutterBottom>Shipping Address</Typography>

            {address ? (
              <>
                <Typography>{address.name}</Typography>
                <Typography>{address.street1}</Typography>
                <Typography>{`${address.city}, ${address.state} ${address.zip}`}</Typography>
                <Typography>United States</Typography>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">No address provided</Typography>
            )}
          </Column>

          {/* Column 2: Payment Method */}
          <Column>
            <Typography variant="h6" gutterBottom>Payment Method</Typography>
            <Chip 
              icon={<AccountBalanceWalletIcon />} 
              label="WALLET" 
              clickable 
              component="a" 
              href="/wallet" 
              color="primary" 
              sx={{ borderRadius: '16px', padding: '8px' }} 
            />
          </Column>

          {/* Column 3: Order Summary */}
          <Column>
            <Typography variant="h6" gutterBottom>Order Summary</Typography>
            <Typography variant="body2">Item(s) Subtotal: ${(order.price / 100).toFixed(2)}</Typography>
            <Typography variant="body2">Shipping & Handling: $0.00</Typography>
            <Typography variant="body2">Estimated tax to be collected: $0.00</Typography>
            <Typography variant="body2">App Fee: $0.00</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Grand Total: ${calculateGrandTotal(order.price, order.volume)}</Typography>
          </Column>
        </ThreeColumnBox>

        {/* Existing Order Box */}
        <OrderBox>
          <OrderHeader>
            <Box>
              <Typography variant="body2" color="textSecondary">TRADE EXECUTED</Typography>
              <Typography variant="body2">{new Date(order.created_at).toLocaleDateString()}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">TOTAL</Typography>
              <Typography variant="body2">${calculateGrandTotal(order.price, order.volume)}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">SHIP TO</Typography>
              {renderShipToTooltip()}
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">BID ID</Typography>
              <Typography variant="body2">{order.order.uuid}</Typography>
            </Box>
          </OrderHeader>

          <OrderItem>
            <OrderDetailsBox>
              {renderShipmentStatus()}

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {productDetails.img ? (
                  <img
                    src={productDetails.img}
                    alt={productDetails.title}
                    style={{ maxWidth: '100px', marginRight: '16px', borderRadius: '4px' }}
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary" style={{ marginRight: '16px' }}>
                    Loading image...
                  </Typography>
                )}

                <div>
                  <Typography variant="body1" gutterBottom>
                    <Link href={`https://www.goldwaterbullion.com/products/${productDetails.slug}`} rel="noopener noreferrer">
                      {productDetails.title || 'Product Name'}
                    </Link>
                  </Typography>
                  <Typography variant="body2" sx={{ alignItems: 'flex-start' }} gutterBottom align="left">
                    Items in shipment: {order.volume}
                  </Typography>
                </div>
              </div>
            </OrderDetailsBox>

            <Stack direction="column" spacing={1}>
              {order.shipment?.tracking_number && order.shipment?.carrier && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: 'none', width: '200px', backgroundColor: '#FFD700', color: '#000', '&:hover': { backgroundColor: '#FFC107' } }}
                  onClick={() => window.open(`https://www.google.com/search?q=${order.shipment.carrier}+tracking+ID+${order.shipment.tracking_number}`, '_blank')}
                >
                  Track Package
                </Button>
              )}
            </Stack>
          </OrderItem>
        </OrderBox>
      </Box>
    </Container>
  );
};

export default OrderDetails;
