import React from 'react';
import { Box, Card, CardContent, Typography, Link, Button, Avatar, Grid } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TableContainer from './Table'
import SellProcess from './SellProcess'
import BuyProcess from './BuyProcess'

const BlogPost = () => {
  // Get the current date
  const currentDate = 'April 30, 2023';

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
      <Grid container spacing={4} sx={{ maxWidth: '1200px' }}>
        
        {/* Left Column: 2/3 Content */}
        <Grid item xs={12} md={8}>
          <Card
            sx={{ boxShadow: 2, borderRadius: 2, padding: '20px' }}
            style={{
              fontFamily: 'Roboto, sans-serif', // Apply Roboto to the card content only
            }}
          >
            <CardContent>
              <Typography variant="h2" sx={{ fontWeight: 400, textAlign: 'left', marginBottom: '20px' }}>
                Your Marketplace for Precious Metals
              </Typography>

              {/* Add caption here */}
              <Typography variant="caption" sx={{ textAlign: 'left', color: 'text.secondary', display: 'block', marginBottom: '20px' }}>
                Buying and selling with confidence
              </Typography>

              {/* Card with avatar, name, and date */}
              <Box sx={{ marginBottom: '20px', bgcolor: 'transparent', padding: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: 'primary.main', marginRight: '10px' }}>
                    JZ
                  </Avatar>
                  <Box>
                    <Typography variant="h6" sx={{ margin: 0 }}>
                      Justin Zollars
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#616161' }}>
                      {currentDate}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Increased line spacing here */}
              <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '20px', lineHeight: 2 }}>
                Howdy! Justin here from Goldwater Bullion Exchange! We are the world's first 
                
                <HtmlTooltip
                    title={
                    <React.Fragment>
                        <u>{'noun'}</u>.{' '}
                        {"a book that shows the number of orders that a company has received from its customers"}
                    </React.Fragment>
                    }
                >
                    <Button>Order Book</Button>
                </HtmlTooltip>
                
                style marketplace for buying and selling gold and silver bullion, where you are able to name your best price, and the marketplace matches it!
                We believe strongly in capitalism, and we've created a beautiful product and trusted marketplace for you. You are able to get the absolute best price 
                for your gold and experience the best treatment and confidence, through the process. The Texas way!
              </Typography>

              <Typography variant="h5" sx={{ fontWeight: 400, textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
            What steps do we take to earn your trust?
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '20px', lineHeight: 2 }}>
            Goldwater Bullion Exchange was founded by people with a real passion for coins! Our 
            
            
            <HtmlTooltip
                title={
                <React.Fragment>
                    <Typography color="inherit">nu·​mis·​mat·​ics</Typography>
                    <em>{"nü-məz- ma-tiks"}</em> <u>{'noun'}</u>.{' '}
                    {"The study or collection of coins, tokens, and paper money and sometimes related objects (such as medals)"}
                </React.Fragment>
                }
            >
                <Button>numismatists</Button>
            </HtmlTooltip>
            
             work under camera in a secure facility in Texas.
            We verify by hand every ounce of gold and silver using the latest scientific techniques. Justin and Robynne, the founders of our company, met
            working in Silicon Valley startups. We are libertarian-oriented, and work with integrity every day. Justin has worked for companies like Adobe and EasyPost. 
            Robynne manages our metal verification facility in Texas.
          </Typography>

          <SellProcess sx={{marginBottom: '20px' }} />
          
          <Typography variant="h5" sx={{ fontWeight: 400, textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
            How do I sell my gold at Goldwater Bullion Exchange?
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '20px', lineHeight: 2 }}>
            Selling on Goldwater Bullion Exchange is done through asks. Search the market, enter your limit price and quantity, and if there's a matching bid, 
            your sell order will execute. Shipping labels and insurance are provided by our partners at EasyPost. You have 1-3 days to send your gold.
          </Typography>
          <TableContainer />

          <Typography variant="h5" sx={{ fontWeight: 400, textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
           Where do I ship items I sold?
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '20px', lineHeight: 2 }}>
            You will ship all items you sell to our Goldwater Bullion metal verification facility in Texas. Upon receiving the shipment, our expert team will verify the authenticity and quality of the gold using the latest techniques. Once verified, we’ll securely package and send the gold to the winning bidder via fully insured shipping. This process ensures that both sellers and buyers can trade with confidence, knowing that all items are carefully inspected before completing the transaction.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 400, textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
            How does Shipping work?
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '20px', lineHeight: 2 }}>
            We provide a fully paid, 
            
            <HtmlTooltip
                title={
                <React.Fragment>
                    <Typography color="inherit">$100,000 Insurance</Typography>
                    <u>{'Ship with confidence'}</u>.{' '}
                    {"Each shipment is insured with up to $100,000 of insurance"}
                </React.Fragment>
                }
            >
                <Button>fully insured</Button>
            </HtmlTooltip>
            
            shipping label through EasyPost, UPS, USPS, and FedEx. Double-box your shipment, photograph contents, 
            and label everything clearly. Do not disclose the contents to the clerk.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 400, textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
            How do I get paid?
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '20px', lineHeight: 2 }}>
            Payments are processed through Stripe after your metals are verified, typically taking 1-3 days through ACH. If eligible, you might receive instant payouts.{' '}
            <Link href="https://support.stripe.com/questions/what-are-instant-payouts-and-who-is-eligible" target="_blank" sx={{ fontWeight: 600 }}>
              Learn more about instant payouts
            </Link>.
          </Typography>

          <BuyProcess sx={{marginBottom: '20px' }} />
          
          <Typography variant="h5" sx={{ fontWeight: 400, textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
            How does buying work?
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '20px', lineHeight: 2 }}>
            At Goldwater Bullion Exchange, buying is done by placing bids. For your safety, we verify both your identity and address. Before you can place a bid, you'll need to fund your account. The bid will temporarily hold your funds, but don’t worry—you can cancel at any time without any fees. Once your bid matches a seller's ask, the order clears instantly. Your payment is held until our expert 
            
            <HtmlTooltip
                title={
                <React.Fragment>
                    <Typography color="inherit">nu·​mis·​mat·​ics</Typography>
                    <em>{"nü-məz- ma-tiks"}</em> <u>{'noun'}</u>.{' '}
                    {"The study or collection of coins, tokens, and paper money and sometimes related objects (such as medals)"}
                </React.Fragment>
                }
            >
                <Button>numismatists</Button>
            </HtmlTooltip>
            
            verify the gold, after which it's shipped to you via 
            
            <HtmlTooltip
                title={
                <React.Fragment>
                    <Typography color="inherit">$100,000 Insurance</Typography>
                    <u>{'Ship with confidence'}</u>.{' '}
                    {"Each shipment is insured with up to $100,000 of insurance"}
                </React.Fragment>
                }
            >
                <Button>fully insured</Button>
            </HtmlTooltip>
            
            FedEx or UPS express mail. You control the price you pay and have full visibility into our buy and sell orders to know where your bid stands.
            </Typography>


            <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
            href="/welcome"  // Direct Flask route
            >
            Get Started
            </Button>

            </CardContent>
          </Card>
        </Grid>

        {/* Right Column: 1/3 Call to Action */}
        <Grid item xs={12} md={4}>
          <Box sx={{ padding: '20px', bgcolor: '#f9f9f9', borderRadius: 2, boxShadow: 2, textAlign: 'left' }}>
            <Typography variant="h5" sx={{ fontWeight: 500, marginBottom: '20px' }}>
              Join the Marketplace
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', lineHeight: 1.8, textAlign: 'left' }}>
              Goldwater Bullion Exchange offers you the ability to trade precious metals with confidence. 
              Sign up today to start buying and selling gold and silver bullion, ensuring you get the best market prices.
            </Typography>
            <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
            href="/welcome"  // Direct Flask route
            >
            Join Today
            </Button>

          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default BlogPost;
